var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('GenericCrud',{attrs:{"route-name":"SettingsCompanyGroupNotification","route-action":"list","navbar-title":_vm.$t('settings.companyGroupNotification.buttonLabel'),"navbar-back-route-without-history":{ name: 'Settings' },"main-fist-scroll-to-selected":"","storeData":{
            mainListItems: {
                action: 'companyGroupNotification/fetchList',
                payload: { force: true },
                options: null,
                itemText: function (x) {
                    return this$1.$t('settings.companyGroupNotification.notificationitems.' + x.alias + '.name');
                },
            },
            mainItemById: {
                action: 'companyGroupNotification/fetchById',
                payload: null,
                options: null,
            },
            edit: {
                action: 'companyGroupNotification/edit',
                payload: null,
                options: null,
                refetch: true,
                hideButton: true
            },
            add: {
                action: 'companyGroupNotification/create',
                payload: null,
                options: null,
            },
            delete: {
                action: 'companyGroupNotification/delete',
                payload: null,
                options: null,
                hideButton: true
            },
        },"newItemModelFields":{
            alias: null,
            title: null,
            deadline: null,
            content: null,
            locale: _vm.currentLanguage,
            translations: _vm.defaultTranslations,
        }},scopedSlots:_vm._u([{key:"editorMask",fn:function(x){return [_c('EditorMask',{key:x.id,attrs:{"aliasItems":_vm.aliasItems,"available-languages":_vm.availableLanguages,"current-language":_vm.currentLanguage,"isWaiting":x.isWaiting,"isModified":x.isModified},on:{"modified":x.onSetModified,"onSaveItem":x.onSaveItem,"onDeleteItem":x.onDeleteItem,"onCreateItem":x.onCreateItem},model:{value:(x.selectedMainItem),callback:function ($$v) {_vm.$set(x, "selectedMainItem", $$v)},expression:"x.selectedMainItem"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }