<template>
    <div>
        <portal to="appBarNavigationTitle">
            {{ navbarTitle }}
        </portal>
        <portal to="appBarNavigationAction">
            <div class="d-flex flex-row justify-end align-center">
                <div>
                    <BaseBackButton :route="navbarBackRouteWithoutHistory" :loading="isWaiting" />
                </div>
                <template v-if="!childCrud && clipboardEnabled">
                    <div
                        v-if="
                            !itemIsModified &&
                                !isWaiting &&
                                selectedMainItem
                        ">
                        <v-tooltip
                            bottom
                            color="primary"
                        >
                            <template v-slot:activator="{ on }">
                                <div v-on="on" class="d-inline-block">
                                    <v-btn
                                        color="white"
                                        outlined
                                        class="ma-2"
                                        fab
                                        small
                                        :elevation="2"
                                        @click="storeInClipboard()"
                                    >
                                        <v-icon v-text="'mdi-clipboard-plus'" />
                                    </v-btn>
                                </div>
                            </template>
                            <span v-text="localeMerged.clipboardCopyButton.tooltip"/>
                        </v-tooltip>
                    </div>
                    <div
                        v-if="
                            !childCrud &&
                            !itemIsModified &&
                            itemInClipboard &&
                            !isWaiting &&
                            selectedMainItem
                        ">
                        <v-tooltip
                            bottom
                            color="primary"
                        >
                            <template v-slot:activator="{ on }">
                                <div v-on="on" class="d-inline-block">
                                    <v-btn
                                        color="white"
                                        outlined
                                        class="ma-2"
                                        fab
                                        small
                                        :elevation="2"
                                        @click="pasteFromClipboard()"
                                    >
                                        <v-icon v-text="'mdi-clipboard-arrow-down'" />
                                    </v-btn>
                                </div>
                            </template>
                            <span v-text="localeMerged.clipboardPasteButton.tooltip" />
                        </v-tooltip>
                    </div>
                </template>
                <div v-if="showDeleteButton">
                    <v-tooltip
                        bottom
                        color="error"
                    >
                        <template v-slot:activator="{ on }">
                            <div v-on="on" class="d-inline-block">
                                <v-btn
                                    color="error"
                                    class="ma-2"
                                    fab
                                    small
                                    :elevation="2"
                                    :loading="$wait.is('deleting selected item')"
                                    :disabled="isWaiting"
                                    @click="showDeletionConfirmation"
                                >
                                    <v-icon v-text="'mdi-close'" />
                                </v-btn>
                            </div>
                        </template>
                        <span v-text="localeMerged.delete.tooltip" />
                    </v-tooltip>
                </div>
                <div v-if="showSaveButton">
                    <div class="d-inline-block ma-2">
                        <v-badge
                            :value="itemIsModified"
                            color="success"
                            bordered
                            dark
                            :offset-y="16"
                            :offset-x="11"
                            icon="mdi-asterisk"
                            class="v-badge-diff v-badge--save"
                            >
                            <v-tooltip bottom color="primary">
                                <template v-slot:activator="{ on }">
                                    <div v-on="on" class="d-inline-block">
                                        <v-btn
                                            color="white"
                                            outlined
                                            :loading="$wait.is('saving selected item')"
                                            :disabled="!itemIsModified || isWaiting"
                                            fab
                                            small
                                            :elevation="2"
                                            @click="saveItem()"
                                        >
                                            <v-icon v-text="'mdi-content-save-edit'" />
                                        </v-btn>
                                    </div>
                                </template>
                                <span v-text="localeMerged.save.tooltip" />
                            </v-tooltip>
                        </v-badge>
                    </div>
                </div>
                <div
                    v-if="showCreateButton">
                    <v-tooltip
                        bottom
                        color="success"
                    >
                        <template v-slot:activator="{ on }">
                            <div v-on="on" class="d-inline-block">
                                <v-btn
                                    color="success"
                                    class="ma-2"
                                    fab
                                    small
                                    :elevation="2"
                                    @click="onClickCreate()"
                                >
                                    <v-icon  v-text="'add'" />
                                </v-btn>
                            </div>
                        </template>
                        <span v-text="localeMerged.create.tooltip" />
                    </v-tooltip>
                </div>
            </div>
        </portal>
        <v-container
            fluid
            class="px-3 py-0 pt-3"
        >
            <v-row class="justify-space-arround fill-height">
                <v-col cols="12" :md="childCrud && selectedMainItem ? 6 : 12" lg="3" xl="2" class="py-0">
                    <GenericItemList
                        :disable-sorting="storeData.mainListItems.disableSorting"
                        :items="mainListItems"
                        :itemText="storeData.mainListItems.itemText || null"
                        :selected="selectedMainId"
                        :max-height="'100%'"
                        :filter="!!mainListFilterLabel"
                        :filterLabel="mainListFilterLabel"
                        :scope="mainListFilterLabel"
                        :scrollToSelected="mainListScrollToSelected"
                        :disabledItem="storeData.mainListItems.disabled"
                        :customItem="storeData.mainListItems.customized"
                        :loading="$wait.is('loading main list items')"
                        @select="onClickMainItem($event)"
                    />
                </v-col>
                <v-col
                    v-if="!childCrud || !selectedMainItem"
                    cols="12"
                    lg="9"
                    xl="10"
                    class="py-0">
                    <template v-if="!selectedMainItem && !$wait.is('loading selected main item')">
                        <div>
                            <v-alert
                                prominent
                                text
                                border="left"
                                type="info"
                                icon="mdi-information-outline"
                                class="mt-3"
                                :max-width="$vuetify.breakpoint.xl ? '70%' : '100%'"
                                >
                                <v-row align="center">
                                    <v-col class="grow">
                                        <span v-if="storeData.add && showCreateButton" v-text="localeMerged.selectOrCreateInfo" />
                                        <span v-else v-text="localeMerged.selectInfo" />
                                    </v-col>
                                    <v-col v-if="$vuetify.breakpoint.lgAndUp" class="shrink">
                                        <v-btn
                                            v-if="storeData.add && showCreateButton"
                                            class="ml-3"
                                            color="success"
                                            depressed
                                            @click="onClickCreate()"
                                        >
                                            <v-icon left>add</v-icon>
                                            <span v-text="localeMerged.create.tooltip" />
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-alert>
                        </div>
                    </template>
                    <template v-else-if="selectedMainItem && !childCrud && !$wait.is('loading selected main item')">
                        <slot
                            name="editorMask"
                            :id="
                                selectedMainItem.id
                                    ? selectedMainItem.id
                                    : 'new'
                            "
                            :isWaiting="isWaiting"
                            :selectedMainItem="selectedMainItem"
                            :isModified="itemIsModified"
                            :onSetModified="setItemIsModified"
                            :onSaveItem="saveItem"
                            :onDeleteItem="showDeletionConfirmation"
                            :onCreateItem="onClickCreate"
                            :onFindUsage="onFindUsage"
                        ></slot>
                    </template>
                </v-col>
                <v-col
                    v-if="
                        childCrud &&
                            selectedMainItem &&
                            !$wait.is('loading selected main item')
                    "
                    cols="12"
                    :md="selectedMainItem && selectedMainItem ? 6 : 12"
                    lg="3"
                    xl="2"
                    class="py-0"
                >
                    <template v-if="selectedMainItem">
                        <GenericItemList
                            disableSorting
                            :items="storeData.mainItemById.listProp ? selectedMainItem[storeData.mainItemById.listProp] : selectedMainItem"
                            :itemText="storeData.mainItemById.itemText"
                            :selected="selectedChildId"
                            :max-height="'100%'"
                            :filter="!!storeData.mainItemById.filterLabel"
                            :filterLabel="storeData.mainItemById.filterLabel"
                            :scope="storeData.mainItemById.filterLabel"
                            :scrollToSelected="
                                storeData.mainItemById.scrollToSelected
                            "
                            :disabledItem="storeData.mainItemById.disabled"
                            :customItem="storeData.mainItemById.customized"
                            ref="childItemList"
                            @select="onClickChildItem($event)"
                        />
                    </template>
                </v-col>
                <v-col
                    v-if="
                        childCrud &&
                            selectedMainItem &&
                            !$wait.is('loading selected main item') &&
                            !$wait.is('loading selected child item')
                    "
                    cols="12"
                    lg="6"
                    xl="8"
                    class="py-0"
                >
                    <template v-if="selectedChildItem">
                        <slot
                            name="editorMask"
                            :id="
                                selectedChildItem.id
                                    ? selectedChildItem.id
                                    : 'new'
                            "
                            :isWaiting="isWaiting"
                            :selectedChildItem="selectedChildItem"
                            :parentId="selectedMainId"
                            :isModified="itemIsModified"
                            :onSetModified="setItemIsModified"
                            :onSaveItem="saveItem"
                            :onDeleteItem="showDeletionConfirmation"
                            :onCreateItem="onClickCreate"
                            :onFindUsage="onFindUsage"
                        ></slot>
                    </template>
                    <template v-else-if="selectedMainItem">
                        <div
                            :class="{
                                'mt-3': mainListItems && mainListItems.length,
                                'mt-5 pt-3':
                                    !mainListItems || !mainListItems.length,
                            }"
                        >
                            <v-alert
                                prominent
                                text
                                border="left"
                                type="info"
                                icon="mdi-information-outline"
                                class="mt-3"
                                :max-width="$vuetify.breakpoint.xl ? '70%' : '100%'"
                                >
                                <v-row align="center">
                                    <v-col class="grow">
                                        <template v-if="storeData.add">{{
                                            localeMerged.selectOrCreateInfo
                                        }}</template>
                                        <template v-else>{{
                                            localeMerged.selectInfo
                                        }}</template>
                                    </v-col>
                                    <v-col v-if="$vuetify.breakpoint.lgAndUp" class="shrink">
                                        <v-btn
                                            v-if="storeData.add"
                                            class="ml-3"
                                            color="success"
                                            depressed
                                            @click="onClickCreate()"
                                        >
                                            <v-icon left>add</v-icon>
                                            <span v-text="localeMerged.create.tooltip" />
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-alert>
                        </div>
                    </template>
                </v-col>
            </v-row>
        </v-container>
        <BaseConfirmationDialog
            v-model="deletionDialog"
            :title="localeMerged.deleteConfirmation.headline"
            :text="localeMerged.deleteConfirmation.text"
            :confirmationText="localeMerged.deleteConfirmation.delete"
            :cancelText="localeMerged.deleteConfirmation.cancel"
            buttonColorConfirm="warning"
            @confirm="deleteItem()"
        />
        <BaseConfirmationDialog
            v-model="confirmationDialog.state"
            :title="confirmationDialog.headline"
            :text="confirmationDialog.text"
            :confirmationText="confirmationDialog.confirmationText"
            :buttonColorConfirm="confirmationDialog.buttonColorConfirm"
            :cancelText="confirmationDialog.cancelText"
            :showCancel="confirmationDialog.showCancel"
            @confirm="confirmationDialog.confirmFn()"
            @cancel="confirmationDialog.cancelFn()"
            :maxWidth="$vuetify.breakpoint.smAndDown ? '95%' : '620px'"
        />
        <BaseResponseDialog
            v-model="baseResponseDialog"
            :error-dialog="baseResponseDialogText.isWarning"
            :headline="baseResponseDialogText.headline"
            :message="baseResponseDialogText.message"
            :items="baseResponseDialogText.items"
            :btnCloseText="baseResponseDialogText.btnCloseText"
            :persistent="$wait.is('replace selected item')"
        >
            <template v-slot:actions>
                <div v-if="baseResponseDialogText.enableReplacement && baseResponseDialogText.items.length" class="w100">
                    <v-alert
                        border="left"
                        dense
                        outlined
                        text
                        color="warning"
                    >
                    <h3 class="title" v-t="baseResponseDialogText.replacementAlertTitle" />
                        <div class="body-2" v-t="baseResponseDialogText.replacementAlertText" />
                        <v-divider
                            class="my-4 info"
                            style="opacity: 0.22"
                        ></v-divider>
                        <v-row
                            v-if="usageActionTypeItems.length > 1"
                            align="start"
                            no-gutters
                        >
                            <v-col class="grow">
                                <v-autocomplete
                                    :items="usageActionTypeItems"
                                    v-model="selectedUsageActionTypeModel"
                                    dense
                                    solo
                                    full-width
                                    clearable
                                    :label="localeMerged.usageActionType.label"
                                    item-value="action"
                                    item-text="title"
                                    color="primary"
                                    :disabled="$wait.is('replace selected item')"
                                />
                            </v-col>
                        </v-row>
                        <v-row
                            align="start"
                            no-gutters
                        >
                            <v-col class="grow">
                                <v-autocomplete
                                    :items="mainListItems.filter(x => x.id !== parseInt($route.params.id))"
                                    v-model="replaceWithModel"
                                    dense
                                    solo
                                    full-width
                                    prepend-inner-icon="mdi-find-replace"
                                    clearable
                                    :label="baseResponseDialogText.replacementAlertDropdownLabel"
                                    item-value="id"
                                    :item-text="function(e) {
                                        return e[storeData.mainListItems.itemText] + ' (ID: ' + e.id + ')'
                                    }"
                                    color="primary"
                                    :disabled="$wait.is('replace selected item') || replaceWithDisabled"
                                />
                            </v-col>
                            <v-col class="shrink">
                                <v-btn
                                    @click="showReplacementConfirmation()"
                                    :disabled="usageActionButtonDisabled"
                                    class="ml-3"
                                    color="warning"
                                    :loading="$wait.is('replace selected item')"
                                    v-text="baseResponseDialogText.replacementAlertActionLabel"
                                />
                            </v-col>
                        </v-row>
                    </v-alert>
                </div>
            </template>
        </BaseResponseDialog>
        <v-snackbar
            app
            v-model="feedbackSnackbar"
            :color="$vuetify.theme.dark ? 'success' : 'success darken-1'"
            :timeout="4000"
            top
            centered
            :text="$vuetify.theme.dark"
        >
            <v-card-text class="pt-4">{{ feedbackSnackbarText }}</v-card-text>
            <template v-slot:action="{ attrs }">
                <v-btn
                class="mt-n6"
                text
                fab
                small
                v-bind="attrs"
                @click="feedbackSnackbar = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { v4 as uuid } from 'uuid';
import snakeCase from 'lodash/snakeCase';
import { isEqual } from 'lodash';
import titleMixin from '@/mixins/titleMixin';
import ScrollHeaderMixin from '@/components/vvt/ScrollHeaderMixin';
const GenericItemList = () => import(/* webpackChunkName: "GenericItemList" */ '@/components/generic/list/ItemList.vue');

export default {
    name: 'GenericCrud',
    components: {
        GenericItemList,
    },
    mixins: [titleMixin, ScrollHeaderMixin],
    inject: {
        $validator: '$validator',
    },
    i18n: {
        messages: {
            en: require('@/locales/Settings/en.json'),
            de: require('@/locales/Settings/de.json'),
        },
    },
    title() {
        return this.navbarTitle;
    },
    /**
     * @prop {?Boolean} childCrud                     - childCrud is for operations (create, edit, delete) on nested datasets like departments which belongs to a company
     * @prop {String}   routeName                     - Name of the main route from router.js
     * @prop {?String}  routeAction                   - Action route param
     * @prop {?String}  routeChildAction              - Child action route param
     * @prop {?String}  navbarTitle                   - Injected title for the layout header
     * @prop {?Object}  navbarBackRouteWithoutHistory - Route object for the layout header if no browser history exists
     * @prop {?String}  mainListFilterLabel           - Label for filter input above the main list. Set null to disable filter (old: filterLabel)
     * @prop {?Boolean} mainListScrollToSelected      - If the main list should scroll to selected item (old: listScrollToSelected)
     * @prop {Object}   storeData                     - (old: fetchCalls)
     * @prop {?Object}  newItemModelFields            - properties will be added to selectedMainItem when creating a dataset (old: formFields)
     * @prop {?String}  refetchAfterSaving            - store action which will be executed after saving the entity. The store item (mainItem or childItem) will be loaded again, after that store action.
     * @prop {?Object}  locale                        - locales
     */
    props: {
        childCrud: {
            type: Boolean,
            default: false,
        },
        routeName: {
            type: String,
            default: null,
        },
        routeAction: {
            type: String,
            default: 'list',
        },
        routeChildAction: {
            type: String,
            default: 'details',
        },
        navbarTitle: {
            type: String,
            default: 'CRUD',
        },
        navbarBackRouteWithoutHistory: {
            type: Object,
            default() {
                return {
                    name: 'Home',
                };
            },
        },
        mainListFilterLabel: {
            type: String,
            default: null,
        },
        mainListScrollToSelected: {
            type: Boolean,
            default: true,
        },
        storeData: {
            type: Object,
            default() {
                return {
                    mainListItems: null,
                    mainItemById: null,
                    delete: null,
                    edit: null,
                    add: null,
                    clipboardProp: null,
                    ignoredClipboardProps: [],
                };
            },
        },
        newItemModelFields: {
            type: Object,
            default() {
                return {
                    title: null,
                };
            },
        },
        refetchAfterSaving: {
            type: String,
            default: null,
        },
        locale: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            feedbackSnackbar: false,
            feedbackSnackbarText: null,
            createMode: false,
            mainListItems: [],
            selectedMainItem: null,
            selectedMainId: null,
            selectedChildItem: null,
            selectedChildId: null,
            deletionDialog: false,
            itemIsModified: false,
            confirmationDialog: {
                state: false,
                headline: null,
                text: null,
                confirmationText: null,
                cancelText: null,
                confirmFn: () => {},
                cancelFn: () => {},
                showCancel: false,
                buttonColorConfirm: null,
            },
            baseResponseDialog: false,
            baseResponseDialogText: {
                isWarning: null,
                enableReplacement: false,
                headline: null,
                message: null,
                items: [],
                replacementAlertTitle: null,
                replacementAlertText: null,
                replacementAlertDropdownLabel: null,
                replacementAlertActionLabel: null,
                btnCloseText: null
            },
            replaceWithModel: null,
            selectedUsageActionTypeModel: null,
        };
    },
    computed: {
        ...mapGetters({
            getClipboard: 'clipboard/getItem',
        }),
        usageActionTypeItems() {
            return this.localeMerged.usageActionType.items || [];
        },
        replaceWithDisabled() {
            if(this.usageActionTypeItems.length <= 1) {
                return false;
            }
            if(!this.selectedUsageActionTypeModel) {
                return true;
            }
            let selectedItem = this.usageActionTypeItems.find(x => x.action === this.selectedUsageActionTypeModel);

            return selectedItem.disableTargetDropdown || false;
        },
        usageActionButtonDisabled() {
            if(this.replaceWithDisabled && this.selectedUsageActionTypeModel) {
                let selectedItem = this.usageActionTypeItems.find(x => x.action === this.selectedUsageActionTypeModel);
                if(selectedItem.disableTargetDropdown) {
                    return false;
                }
            }
            
            return !!this.replaceWithModel === false;
        },
        isWaiting() {
            return this.$wait.is(['loading main list items', 'loading selected main item', 'loading selected child item', 'saving selected item', 'deleting selected item', 'replace selected item', 'find usage']);
        },
        showCreateButton() {
            if (this.childCrud) {
                return (
                    !this.$wait.is([
                        'saving selected item',
                        'loading selected main item',
                    ]) &&
                    this.selectedMainItem &&
                    !this.createMode &&
                    this.storeData.add
                );
            }
            return !this.createMode && this.storeData.add;
        },
        showDeleteButton() {
            if (this.createMode) {
                return false;
            }
            if(this.storeData.delete && this.storeData.delete.hideButton) {
                return false;
            }
            if (this.childCrud) {
                return (
                    !this.isWaiting &&
                    this.selectedChildItem &&
                    !this.createMode &&
                    this.storeData.delete
                );
            }
            return (
                !this.isWaiting &&
                this.selectedMainItem &&
                this.storeData.delete
            );
        },
        showSaveButton() {
            if(this.storeData.edit && this.storeData.edit.hideButton) {
                return false;
            }
            if (this.childCrud) {
                return (
                    !this.isWaiting &&
                    this.selectedChildItem &&
                    this.storeData.edit
                );
            }
            return (
                !this.isWaiting &&
                this.selectedMainItem &&
                this.storeData.edit
            );
        },
        clipboardEnabled() {
            return !!this.storeData.clipboardProp;
        },
        itemInClipboard() {
            if (!this.clipboardEnabled) {
                return false;
            }
            return this.getClipboard(this.storeData.clipboardProp);
        },
        localeMerged() {
            return {
                ...{
                    usageActionType: {
                        label: this.$t('genericCrud.usageActionType.label'),
                        items: this.$t('genericCrud.usageActionType.items'),
                    },
                    clipboardCopyButton: {
                        tooltip: this.$t('genericCrud.clipboardCopyButton.tooltip'),
                        dialog: {
                            title: this.$t(
                                'genericCrud.clipboardCopyButton.dialog.title'
                            ),
                            text: this.$t(
                                'genericCrud.clipboardCopyButton.dialog.text'
                            ),
                            confirmationText: this.$t(
                                'genericCrud.clipboardCopyButton.dialog.confirmationText'
                            ),
                        },
                    },
                    clipboardPasteButton: {
                        tooltip: this.$t(
                            'genericCrud.clipboardPasteButton.tooltip'
                        ),
                        dialog: {
                            title: this.$t(
                                'genericCrud.clipboardPasteButton.dialog.title'
                            ),
                            text: this.$t(
                                'genericCrud.clipboardPasteButton.dialog.text'
                            ),
                            cancelText: this.$t(
                                'genericCrud.clipboardPasteButton.dialog.cancelText'
                            ),
                            confirmationText: this.$t(
                                'genericCrud.clipboardPasteButton.dialog.confirmationText'
                            ),
                        },
                    },
                    save: {
                        tooltip: this.$t('genericCrud.save.tooltip'),
                    },
                    create: {
                        tooltip: this.$t('genericCrud.create.tooltip'),
                    },
                    delete: {
                        tooltip: this.$t('genericCrud.delete.tooltip'),
                    },
                    selectOrCreateInfo: this.$t(
                        'genericCrud.selectOrCreateInfo'
                    ),
                    selectInfo: this.$t('genericCrud.selectInfo'),
                    deleteConfirmation: {
                        headline: this.$t('genericCrud.deleteConfirmation.headline'),
                        text: this.$t('genericCrud.deleteConfirmation.text'),
                        delete: this.$t('genericCrud.deleteConfirmation.delete'),
                        cancel: this.$t('genericCrud.deleteConfirmation.cancel'),
                    },
                    replaceConfirmation: {
                        headline: this.$t('genericCrud.replace.confirm.headline'),
                        text: this.$t('genericCrud.replace.confirm.text'),
                        confirmationText: this.$t('genericCrud.replace.confirm.confirmationText')
                    }
                },
                ...this.locale,
            };
        },
    },
    methods: {
        ...mapActions({
            setToClipboard: 'clipboard/set',
        }),
        setItemIsModified() {
            this.itemIsModified = true;
        },
        showDeletionConfirmation() {
            this.deletionDialog = true;
        },
        showReplacementConfirmation() {
            this.confirmationDialog.state = true;
            this.confirmationDialog.headline = this.localeMerged.replaceConfirmation.headline;
            this.confirmationDialog.text = this.localeMerged.replaceConfirmation.text;
            this.confirmationDialog.showCancel = true;
            this.confirmationDialog.confirmationText = this.localeMerged.replaceConfirmation.confirmationText;
            this.confirmationDialog.buttonColorConfirm = 'warning';
            this.confirmationDialog.cancelFn = this.closeConfirmationDialog;
            this.confirmationDialog.confirmFn = this.replaceItems;
        },
        storeInClipboard() {
            this.setToClipboard({
                property: this.storeData.clipboardProp,
                data: this.selectedMainItem,
            });
            this.confirmationDialog.state = true;
            this.confirmationDialog.headline = this.localeMerged.clipboardCopyButton.dialog.title;
            this.confirmationDialog.text = this.localeMerged.clipboardCopyButton.dialog.text;
            this.confirmationDialog.showCancel = false;
            this.confirmationDialog.confirmationText = this.localeMerged.clipboardCopyButton.dialog.confirmationText;
            this.confirmationDialog.buttonColorConfirm = 'primary';
            this.confirmationDialog.confirmFn = this.closeConfirmationDialog;
        },
        pasteFromClipboard() {
            const ignoredClipboardProperties = [
                ...['id'],
                ...this.storeData.ignoredClipboardProps,
            ];
            const objRemainingProps = {};
            ignoredClipboardProperties.forEach(x => {
                objRemainingProps[x] = this.selectedMainItem[x];
            });
            this.selectedMainItem = {
                ...this.itemInClipboard,
                ...objRemainingProps,
            };
            this.confirmationDialog.state = true;
            this.confirmationDialog.headline = this.localeMerged.clipboardPasteButton.dialog.title;
            this.confirmationDialog.text = this.localeMerged.clipboardPasteButton.dialog.text;
            this.confirmationDialog.cancelText = this.localeMerged.clipboardPasteButton.dialog.cancelText;
            this.confirmationDialog.showCancel = true;
            this.confirmationDialog.confirmationText = this.localeMerged.clipboardPasteButton.dialog.confirmationText;
            this.confirmationDialog.buttonColorConfirm = 'warning';
            this.confirmationDialog.confirmFn = this.clearClipboardAndCloseDialog;
            this.confirmationDialog.cancelFn = this.closeConfirmationDialog;

            this.setItemIsModified();
        },
        closeConfirmationDialog() {
            this.confirmationDialog.state = false;
        },
        clearClipboardAndCloseDialog() {
            this.setToClipboard({
                property: this.storeData.clipboardProp,
                data: null,
            });
            this.closeConfirmationDialog();
        },
        async fetchMainListItems() {
            return new Promise((resolve, reject) => {
                this.$wait.start('loading main list items');
                this.$store
                    .dispatch(
                        this.storeData.mainListItems.action,
                        (this.storeData.mainListItems.payload || {})
                    )
                    .then(response => {
                        this.$wait.end('loading main list items');
                        resolve(response);
                    })
                    .catch(e => {
                        this.$wait.end('loading main list items');
                        reject(e);
                    });
            });
        },
        async loadMainItemById(id) {
            return new Promise((resolve, reject) => {
                this.$wait.start('loading selected main item');
                this.fetchMainItemById(id)
                    .then(response => {
                        this.selectedMainItem = response.data;
                        this.itemIsModified = false;
                        this.$wait.end('loading selected main item');
                        resolve(response);
                    })
                    .catch(() => {
                        this.$wait.end('loading selected main item');
                        this.closeMainItem();
                        reject();
                    });
            });
        },
        async loadChildItemById(id) {
            return new Promise((resolve, reject) => {
                this.$wait.start('loading selected child item');
                this.fetchChildItemById(id)
                    .then(response => {
                        this.selectedChildItem = response.data;
                        this.itemIsModified = false;
                        this.$wait.end('loading selected child item');
                        resolve(response);
                    })
                    .catch(() => {
                        this.$wait.end('loading selected child item');
                        this.closeChildItem();
                        reject();
                    });
            });
        },
        async fetchMainItemById(id) {
            return new Promise((resolve, reject) => {
                this.$store
                    .dispatch(this.storeData.mainItemById.action, {
                        ...{ id: id },
                        ...(this.storeData.mainItemById.payload || {}),
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(e => {
                        reject(e);
                    });
            });
        },
        async fetchChildItemById(id) {
            return new Promise((resolve, reject) => {
                this.$store
                    .dispatch(this.storeData.childItemById.action, {
                        ...{ id: id },
                        ...(this.storeData.childItemById.payload || {}),
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(e => {
                        reject(e);
                    });
            });
        },
        async post(api, payload) {
            return new Promise((resolve, reject) => {
                this.$store
                    .dispatch(api, payload)
                    .then(response => {
                        if (response.status === 412 || response.data.error) {
                            reject(response);
                        } else {
                            resolve(response);
                        }
                    })
                    .catch(e => {
                        reject(e);
                    });
            });
        },
        async fetchData() {
            await this.fetchMainListItems().then(x => {
                if (this.storeData.mainListItems.filterFetchedItems) {
                    this.mainListItems = this.generateMainListItemsFromResult(x.data).filter(x => x[this.storeData.mainListItems.filterFetchedItems]);
                } else {
                    this.mainListItems = this.generateMainListItemsFromResult(x.data);
                }
                this.dataFetched();
            });
        },
        generateMainListItemsFromResult (result) {
            if (this.storeData.mainListItems.itemsProperty && result[this.storeData.mainListItems.itemsProperty]) {
                return result[this.storeData.mainListItems.itemsProperty];
            } else {
                return result;
            }
        },
        dataFetched() {
            this.handleRoute(this.$route.params);
        },
        handleRoute(routeParams) {
            const paramAction = routeParams.action;
            const paramId = routeParams.id;
            const paramChildAction = routeParams.childAction;
            const paramChildId = routeParams.childId;

            if (paramAction === this.routeAction) {
                if (paramId === 'create' && !this.childCrud) {
                    this.createMainItem();
                } else if (
                    (!this.childCrud && paramId > 0) ||
                    (this.childCrud &&
                        this.selectedMainId !== paramId &&
                        paramId > 0)
                ) {
                    this.selectMainItem(paramId);
                    if (
                        this.childCrud &&
                        paramChildAction === this.routeChildAction &&
                        paramChildId > 0
                    ) {
                        this.selectChildItem(paramChildId);
                    } else if (
                        this.childCrud &&
                        paramChildAction === this.routeChildAction &&
                        paramChildId === 'create'
                    ) {
                        this.createChildItem();
                    } else if (this.childCrud) {
                        this.closeChildItem();
                    }
                } else if (
                    this.childCrud &&
                    paramChildAction === this.routeChildAction &&
                    paramChildId === 'create'
                ) {
                    this.createChildItem();
                } else if (
                    paramChildAction === this.routeChildAction &&
                    paramChildId > 0 &&
                    this.selectedMainItem
                ) {
                    this.selectChildItem(paramChildId);
                } else if (
                    paramChildAction === this.routeChildAction &&
                    !paramChildId &&
                    this.selectedMainItem
                ) {
                    this.closeChildItem();
                } else if (this.selectedMainItem) {
                    this.closeMainItem();
                }
            }
        },
        onClickMainItem(id) {
            if (!this.$wait.is('loading selected main item')) {
                let route = {};
                if (id === this.selectedMainId) {
                    route = {
                        name: this.routeName,
                        params: {
                            action: this.routeAction,
                        },
                    };
                } else {
                    route = {
                        name: this.routeName,
                        params: {
                            action: this.routeAction,
                            id: parseInt(id),
                        },
                    };
                }
                this.$router.push(JSON.parse(JSON.stringify(route)));
            }
        },
        onClickChildItem(id) {
            if (!this.$wait.is('loading selected child item')) {
                let route = {};
                if (id === this.selectedChildId) {
                    route = {
                        name: this.routeName,
                        params: {
                            action: this.routeAction,
                            id: this.selectedMainId,
                            childAction: this.routeChildAction,
                        },
                    };
                } else {
                    route = {
                        name: this.routeName,
                        params: {
                            action: this.routeAction,
                            id: this.selectedMainId,
                            childAction: this.routeChildAction,
                            childId: parseInt(id),
                        },
                    };
                }
                this.$router.push(JSON.parse(JSON.stringify(route)));
            }
        },
        selectMainItem(id) {
            if (id === this.selectedMainId && !this.selectedChildId) {
                this.closeMainItem();
            } else {
                this.openMainItem(id);
            }
        },
        selectChildItem(id) {
            if (id === this.selectedChildId && !this.selectedChildId) {
                this.closeChildItem();
            } else {
                this.openChildItem(id);
            }
        },
        closeMainItem() {
            this.selectedMainItem = null;
            this.selectedMainId = null;
            this.selectedChildItem = null;
            this.selectedChildId = null;
            this.createMode = false;
        },
        closeChildItem() {
            this.selectedChildItem = null;
            this.selectedChildId = null;
        },
        openMainItem(id) {
            id = parseInt(id);
            this.loadMainItemById(id);
            this.selectedMainId = id;
            this.selectedChildId = null;
            this.createMode = false;
        },
        openChildItem(id) {
            id = parseInt(id);
            this.loadChildItemById(id);
            this.selectedChildId = id;
            this.createMode = false;
        },
        deleteItem() {
            if (
                (!this.childCrud && this.selectedMainItem) ||
                (this.childCrud && this.selectedChildItem)
            ) {
                this.deleting = true;
                this.$wait.start('deleting selected item');
                const loacalPayload = this.childCrud
                    ? this.selectedChildItem
                    : this.selectedMainItem;
                this.post(
                    this.storeData.delete.action,
                    { ...loacalPayload, ...(this.storeData.delete.payload || {}) },
                    this.storeData.delete.options
                )
                    .then((response) => {
                        this.$wait.end('deleting selected item');
                        this.$wait.end('saving selected item');
                        this.$emit('deleted', response.data);
                        this.showSnackbar('delete');
                        if (this.childCrud) {
                            this.loadMainItemById(this.selectedMainId);
                            this.onClickChildItem(this.selectedChildItem.id);
                        } else {
                            this.fetchMainListItems().then(x => {
                                this.mainListItems = this.generateMainListItemsFromResult(x.data);
                                this.itemIsModified = false;
                            });
                            this.onClickMainItem(this.selectedMainItem.id);
                        }
                    })
                    .catch(e => {
                        this.handleApiError(e);
                        this.$wait.end('saving selected item');
                        this.$wait.end('deleting selected item');
                    });
            }
        },
        onClickCreate() {
            const route = {
                name: this.routeName,
                params: {
                    action: this.routeAction,
                    id: this.childCrud ? this.selectedMainId : 'create',
                    childAction: this.childCrud ? this.routeChildAction : null,
                    childId: this.childCrud ? 'create' : null,
                },
            };
            this.$router.push(JSON.parse(JSON.stringify(route)));
        },
        createMainItem() {
            this.$wait.start('loading selected main item');
            this.closeMainItem();
            const id = uuid();
            this.selectedMainItem = {
                ...{ id: id },
                ...this.newItemModelFields,
            };
            this.createMode = true;
            this.$wait.end('loading selected main item');
        },
        createChildItem() {
            this.$wait.start('loading selected child item');
            this.closeChildItem();
            const id = uuid();
            this.selectedChildItem = {
                ...{ id: id },
                ...this.newItemModelFields,
            };
            this.createMode = true;
            this.$wait.end('loading selected child item');
        },
        async saveItem() {
            this.$validator.validate().then(validationResult => {
                if (validationResult) {
                    this.$wait.start('saving selected item');
                    const loacalPayload = this.childCrud
                        ? this.selectedChildItem
                        : this.selectedMainItem;
                    if (!this.createMode) {
                        this.post(
                            this.storeData.edit.action,
                            {
                                ...loacalPayload,
                                ...(this.storeData.edit.payload || {}),
                            },
                            this.storeData.edit.options
                        )
                            .then(response => {
                                this.afterSaving(response);
                            })
                            .catch(e => {
                                this.handleApiError(e);
                                this.$wait.end('saving selected item');
                            });
                    } else {
                        this.post(
                            this.storeData.add.action,
                            { ...loacalPayload, ...(this.storeData.add.payload || {}) },
                            this.storeData.add.options
                        )
                            .then(response => {
                                this.afterSaving(response);
                            })
                            .catch(e => {
                                this.handleApiError(e);
                                this.$wait.end('saving selected item');
                            });
                    }
                }
            });
        },
        clearBaseResponseDialog () {
            this.baseResponseDialog = false;
            this.baseResponseDialogText.isWarning = null;
            this.baseResponseDialogText.headline = null;
            this.baseResponseDialogText.message = null;
            this.baseResponseDialogText.items = [];
            this.baseResponseDialogText.alertText = null;
            this.baseResponseDialogText.replacementAlertTitle = null;
            this.baseResponseDialogText.replacementAlertText = null;
            this.baseResponseDialogText.replacementAlertDropdownLabel = null;
            this.baseResponseDialogText.replacementAlertActionLabel = null;
            this.baseResponseDialogText.btnCloseText = null;
            this.replaceWithModel = null;
            this.selectedUsageActionTypeModel = null;
        },
        onFindUsage () {
            this.clearBaseResponseDialog();
            
            const sourceId = this.childCrud
                ? this.selectedChildItem ? parseInt(this.selectedChildItem.id) : null
                : this.selectedMainItem ? parseInt(this.selectedMainItem.id) : null;

            if(sourceId) {
                this.$wait.start('find usage');
                this.post(
                        this.storeData.findUsage.action,
                        {sourceId},
                        this.storeData.findUsage.options
                    )
                        .then((res) => {
                            const data = res.data;
                            const hasItems = !!(data.items && data.items.length);
                            this.baseResponseDialogText.isWarning = hasItems;
                            this.baseResponseDialogText.enableReplacement = data.message === 'assigned_items' && hasItems && this.$objectPropertyExists(this.storeData, 'findUsage');
                            
                            const localeVarDefault = 'settings.' + data.entity + '.onApiErrorCode.' + snakeCase(data.message) + '.' + snakeCase(data.referencedEntity);
                            const localeMessage = data.items.length ? data.message : 'none_' + data.message;
                            const localeVarState = 'settings.' + data.entity + '.onApiErrorCode.' + snakeCase(localeMessage) + '.' + snakeCase(data.referencedEntity);
                            
                            this.baseResponseDialogText.headline = this.$te(localeVarState + '.headline') ? this.$t(localeVarState + '.headline') : this.$t('error');
                            this.baseResponseDialogText.message = this.$te(localeVarState + '.message') ? this.$t(localeVarState + '.message') : this.$t('genericErrorMessage');

                            if (data.message === 'assigned_items') {
                                this.baseResponseDialogText.items = data.items.map(
                                    x => {
                                        if(data.referencedEntity === 'processingActivity') {
                                            x.route = {
                                                name: x.leaMaster ? 'AdminVvtEditMaster' : x.isTemplate ? 'SettingsVvtGroupTemplatesEdit' : 'VvtEditProcessingActivity',
                                                params: { id: x.id },
                                            };
                                            x.title = x.recordTitle;
                                            x.subtitle = x.companyTitle;
                                        } else if(data.referencedEntity === 'tool') {
                                            x.route = {
                                                name: 'SettingsTools',
                                                params: { action: 'list', id: x.id },
                                        };
                                        } else if(data.referencedEntity === 'contact') {
                                            x.route = {
                                                name: 'SettingsStaff',
                                                params: { action: 'list', id: x.id },
                                            };
                                        }
                                        return x;
                                    }
                                );
                            }
                            if(this.baseResponseDialogText.enableReplacement) {
                                this.baseResponseDialogText.replacementAlertTitle = this.$t(localeVarDefault + '.replacementAlertTitle');
                                this.baseResponseDialogText.replacementAlertText = this.$t(localeVarDefault + '.replacementAlertText');
                                this.baseResponseDialogText.replacementAlertDropdownLabel = this.$t(localeVarDefault + '.replacementAlertDropdownLabel');
                                this.baseResponseDialogText.replacementAlertActionLabel = this.$t(localeVarDefault + '.replacementAlertActionLabel');
                            } else {
                                this.baseResponseDialogText.btnCloseText = 'back';
                            }
                            this.baseResponseDialog = true;
                            if (
                                !this.$te(localeVarDefault + '.message')
                            ) {
                                console.warn('translation missing for ' + localeVarDefault);
                            }
                            if (
                                !this.$te(localeVarState + '.message')
                            ) {
                                console.warn('translation missing for ' + localeVarState);
                            }
                           
                            this.$wait.end('find usage');
                            
                            this.$wait.end('saving selected item');
                            this.$wait.end('deleting selected item');
                            this.$wait.end('replace selected item');
                            this.$wait.end('find usage');

                        })
                        .catch(e => {
                            this.handleApiError(e);
                            this.$wait.end('saving selected item');
                            this.$wait.end('deleting selected item');
                            this.$wait.end('replace selected item');
                            this.$wait.end('find usage');
                        });

            }
        },
        replaceItems () {
            const localPayload = this.childCrud
                ? {sourceId: this.selectedChildItem ? parseInt(this.selectedChildItem.id) : null, targetId: this.replaceWithModel ? parseInt(this.replaceWithModel) : null, actionType: this.selectedUsageActionTypeModel}
                : {sourceId: this.selectedMainItem ? parseInt(this.selectedMainItem.id) : null, targetId: this.replaceWithModel ? parseInt(this.replaceWithModel) : null, actionType: this.selectedUsageActionTypeModel}

            if(!localPayload.sourceId || (!this.replaceWithDisabled && !localPayload.targetId)) {
                this.replaceWithModel = null;
                this.selectedUsageActionTypeModel = null;
                return;
            }
                
            this.$wait.start('replace selected item');
            this.post(
                    this.storeData.replace.action,
                    localPayload,
                    this.storeData.replace.options
                )
                    .then((response) => {
                        this.$wait.end('saving selected item');
                        this.$wait.end('deleting selected item');
                        this.$wait.end('replace selected item');
                        this.$wait.end('find usage');
                        this.$emit('replaced', response.data);
                        this.clearBaseResponseDialog();
                        this.showSnackbar('replace');

                    })
                    .catch(e => {
                        this.handleApiError(e);
                        this.$wait.end('saving selected item');
                        this.$wait.end('deleting selected item');
                        this.$wait.end('replace selected item');
                        this.$wait.end('find usage');
                    });
        },
        handleApiError(e) {
            const data = e.data;
            const hasError = data && data.error;
            const statusCode = e.status;
            this.clearBaseResponseDialog();
            if (hasError && (data.message === 'existing_field')) {
                this.$validator.reset().then(() =>
                {
                    let fieldName = data.field;
                    const field = this.$validator.fields.find({
                        name: fieldName,
                    });

                    const errMsg = this.$te('settings.' + data.entity + '.onApiErrorCode.' + data.message + '.' + fieldName) ? this.$t('settings.' + data.entity + '.onApiErrorCode.' + data.message + '.' + fieldName) : this.$t('settings.error.' + data.message + '.message');
                    const error = {
                        id: field.id,
                        field: field.name,
                        scope: field.scope,
                        msg: errMsg,
                    };
                    this.errors.add(error);
                });
            }
            else if (hasError && statusCode === 412) {
                this.baseResponseDialogText.isWarning = true;
                this.baseResponseDialogText.enableReplacement = data.message === 'assigned_items' && data.items.length && this.$objectPropertyExists(this.storeData, 'replace') || this.$objectPropertyExists(this.storeData, 'findUsage');
                
                const localeVar = 'settings.' + data.entity + '.onApiErrorCode.' + snakeCase(data.message) + '.' + snakeCase(data.referencedEntity);
                this.baseResponseDialogText.headline = this.$te(localeVar + '.headline') ? this.$t(localeVar + '.headline') : this.$t('error');
                this.baseResponseDialogText.message = this.$te(localeVar + '.message') ? this.$t(localeVar + '.message') : this.$t('genericErrorMessage');

                if (data.message === 'assigned_items') {
                    this.baseResponseDialogText.items = data.items.map(
                        x => {
                            if(data.referencedEntity === 'processingActivity') {
                                x.route = {
                                    name: x.leaMaster ? 'AdminVvtEditMaster' : x.isTemplate ? 'SettingsVvtGroupTemplatesEdit' : 'VvtEditProcessingActivity',
                                    params: { id: x.id },
                                };
                                x.title = x.recordTitle;
                                x.subtitle = x.companyTitle;
                            } else if(data.referencedEntity === 'tool') {
                                x.route = {
                                    name: 'SettingsTools',
                                    params: { action: 'list', id: x.id },
                                };
                            } else if(data.referencedEntity === 'contact') {
                                x.route = {
                                    name: 'SettingsStaff',
                                    params: { action: 'list', id: x.id },
                                };
                            }
                            return x;
                        }
                    );
                }
                if(this.baseResponseDialogText.enableReplacement) {
                    this.baseResponseDialogText.replacementAlertTitle = this.$t(localeVar + '.replacementAlertTitle');
                    this.baseResponseDialogText.replacementAlertText = this.$t(localeVar + '.replacementAlertText');
                    this.baseResponseDialogText.replacementAlertDropdownLabel = this.$t(localeVar + '.replacementAlertDropdownLabel');
                    this.baseResponseDialogText.replacementAlertActionLabel = this.$t(localeVar + '.replacementAlertActionLabel');
                }
                this.baseResponseDialog = true;
                if (
                    !this.$te(localeVar + '.message')
                ) {
                    console.warn('translation missing for ' + localeVar);
                }
           }
            this.$emit('onApiErrorCode', e);
        },
        afterSaving(response) {
            if (this.childCrud) {
                this.loadMainItemById(this.selectedMainId).then(() => {
                    if (this.createMode) {
                        this.onClickChildItem(response.data.id);
                        this.$emit('created', response.data);
                        this.showSnackbar('create');
                    } else {
                        this.$emit('edited', response.data);
                        this.showSnackbar('save');
                    }
                    if (this.refetchAfterSaving) {
                        try {
                            this.$store
                                .dispatch(this.refetchAfterSaving)
                                .then(() => {
                                    this.loadChildItemById(
                                        this.selectedChildId
                                    ).then(
                                        this.$wait.end('saving selected item')
                                    );
                                });
                        } catch (err) {
                            console.warn(
                                `GenericCrud: Unable to dispatch refetchAfterSaving action: ${this.refetchAfterSaving} for child item.`,
                                err
                            );
                        }
                    } else {
                        this.$wait.end('saving selected item');
                    }
                });
            } else {
                if (this.createMode) {
                    this.onClickMainItem(response.data.id);
                    this.$emit('created', response.data);
                    this.showSnackbar('create');
                } else {
                    this.$emit('edited', response.data);
                    this.showSnackbar('save');
                }
                this.fetchMainListItems().then(x => {
                    this.mainListItems = this.generateMainListItemsFromResult(x.data);
                    if (this.refetchAfterSaving) {
                        try {
                            this.$store
                                .dispatch(this.refetchAfterSaving)
                                .then(() => {
                                    this.loadMainItemById(
                                        this.selectedMainId
                                    ).then(
                                        this.$wait.end('saving selected item')
                                    );
                                });
                        } catch (err) {
                            console.warn(
                                `GenericCrud: Unable to dispatch refetchAfterSaving action: ${this.refetchAfterSaving} for main item.`,
                                err
                            );
                        }
                    } else if(!this.createMode && this.storeData.edit.refetch) {
                        this.loadMainItemById(
                            this.selectedMainId
                        ).then(
                            this.$wait.end('saving selected item')
                        );
                    } else {
                        this.$wait.end('saving selected item');
                    }
                });
            }
            this.itemIsModified = false;
        },
        showSnackbar (type) {
            this.feedbackSnackbarText = this.$t('genericCrud.' + type + '.feedback');
            this.feedbackSnackbar = true;
        }
    },
    created() {
        this.fetchData();
        this.initScrollHeader();
    },
    watch: {
        $route(to, from) {
            if (!isEqual(to, from)) {
                this.handleRoute(to.params);
            }
        },
    },
    destroyed() {
        this.createMode = false;
        this.selectedMainItem = null;
        this.selectedMainId = null;
        this.selectedChildId = null;
        this.deletionDialog = false;
        this.mainListItems = [];
    },
};
</script>
