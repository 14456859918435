const DEFAULT_TITLE = document.title;
function getTitle(vm) {
    const { title } = vm.$options;
    if (title) {
        return typeof title === 'function' ? title.call(vm) : title;
    }
}

export default {
    mounted() {
        const title = getTitle(this);
        if (title) {
            document.title = title;
        }
    },
    updated() {
        const title = getTitle(this);
        if (title) {
            document.title = title;
        }
    },
    destroyed() {
        document.title = DEFAULT_TITLE;
    },
};
