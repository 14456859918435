<template>
    <div>
        <GenericCrud
            route-name="SettingsCompanyGroupNotification"
            route-action="list"
            :navbar-title="$t('settings.companyGroupNotification.buttonLabel')"
            :navbar-back-route-without-history="{ name: 'Settings' }"
            main-fist-scroll-to-selected
            :storeData="{
                mainListItems: {
                    action: 'companyGroupNotification/fetchList',
                    payload: { force: true },
                    options: null,
                    itemText: x => {
                        return this.$t('settings.companyGroupNotification.notificationitems.' + x.alias + '.name');
                    },
                },
                mainItemById: {
                    action: 'companyGroupNotification/fetchById',
                    payload: null,
                    options: null,
                },
                edit: {
                    action: 'companyGroupNotification/edit',
                    payload: null,
                    options: null,
                    refetch: true,
                    hideButton: true
                },
                add: {
                    action: 'companyGroupNotification/create',
                    payload: null,
                    options: null,
                },
                delete: {
                    action: 'companyGroupNotification/delete',
                    payload: null,
                    options: null,
                    hideButton: true
                },
            }"
            :newItemModelFields="{
                alias: null,
                title: null,
                deadline: null,
                content: null,
                locale: currentLanguage,
                translations: defaultTranslations,
            }"
        >
            <template v-slot:editorMask="x">
                <EditorMask
                    v-model="x.selectedMainItem"
                    :aliasItems="aliasItems"
                    :key="x.id"
                    :available-languages="availableLanguages"
                    :current-language="currentLanguage"
                    :isWaiting="x.isWaiting"
                    :isModified="x.isModified"
                    @modified="x.onSetModified"
                    @onSaveItem="x.onSaveItem"
                    @onDeleteItem="x.onDeleteItem"
                    @onCreateItem="x.onCreateItem"
                />
            </template>
        </GenericCrud>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GenericCrud from '@/components/generic/GenericCrud.vue';
import EditorMask from './EditorMask.vue';

export default {
    name: 'SettingsCompanyGroupHelpIndex',
    components: {
        GenericCrud,
        EditorMask,
    },
    i18n: {
        messages: {
            en: require('@/locales/Settings/en.json'),
            de: require('@/locales/Settings/de.json'),
        },
    },
    data () {
        return {
            aliasItems: [
                {
                    'alias': 'expired_resubmission',
                    'name': this.$t('settings.companyGroupNotification.notificationitems.expired_resubmission.name'),
                    'description': this.$t('settings.companyGroupNotification.notificationitems.expired_resubmission.description')
                },
                {
                    'alias': 'edit_workflow_init_to_draft',
                    'name': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_init_to_draft.name'),
                    'description': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_init_to_draft.description')
                },
                {
                    'alias': 'edit_workflow_init_to_review',
                    'name': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_init_to_review.name'),
                    'description': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_init_to_review.description')
                },
                {
                    'alias': 'edit_workflow_init_to_approval',
                    'name': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_init_to_approval.name'),
                    'description': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_init_to_approval.description')
                },
                {
                    'alias': 'edit_workflow_init_to_release',
                    'name': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_init_to_release.name'),
                    'description': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_init_to_release.description')
                },
                {
                    'alias': 'edit_workflow_draft_to_draft',
                    'name': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_draft_to_draft.name'),
                    'description': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_draft_to_draft.description')
                },
                {
                    'alias': 'edit_workflow_draft_to_review',
                    'name': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_draft_to_review.name'),
                    'description': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_draft_to_review.description')
                },
                {
                    'alias': 'edit_workflow_review_to_draft',
                    'name': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_review_to_draft.name'),
                    'description': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_review_to_draft.description')
                },
                {
                    'alias': 'edit_workflow_review_to_approval',
                    'name': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_review_to_approval.name'),
                    'description': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_review_to_approval.description')
                },
                {
                    'alias': 'edit_workflow_approval_to_draft',
                    'name': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_approval_to_draft.name'),
                    'description': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_approval_to_draft.description')
                },
                {
                    'alias': 'edit_workflow_approval_to_review',
                    'name': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_approval_to_review.name'),
                    'description': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_approval_to_review.description')
                },
                {
                    'alias': 'edit_workflow_approval_to_release',
                    'name': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_approval_to_release.name'),
                    'description': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_approval_to_release.description')
                },
                {
                    'alias': 'edit_workflow_release_to_draft',
                    'name': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_release_to_draft.name'),
                    'description': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_release_to_draft.description')
                },
                {
                    'alias': 'edit_workflow_release_to_review',
                    'name': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_release_to_review.name'),
                    'description': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_release_to_review.description')
                },
                {
                    'alias': 'edit_workflow_release_to_finalised',
                    'name': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_release_to_finalised.name'),
                    'description': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_release_to_finalised.description')
                },
                {
                    'alias': 'edit_workflow_release_to_finalised_dpiaRequirement',
                    'name': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_release_to_finalised_dpiaRequirement.name'),
                    'description': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_release_to_finalised_dpiaRequirement.description')
                },
                {
                    'alias': 'edit_workflow_finalised_to_draft',
                    'name': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_finalised_to_draft.name'),
                    'description': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_finalised_to_draft.description')
                },
                {
                    'alias': 'edit_workflow_finalised_to_archived',
                    'name': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_finalised_to_archived.name'),
                    'description': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_finalised_to_archived.description')
                },
                {
                    'alias': 'edit_workflow_archived_to_draft',
                    'name': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_archived_to_draft.name'),
                    'description': this.$t('settings.companyGroupNotification.notificationitems.edit_workflow_archived_to_draft.description')
                }
            ]
        }
    },
    computed: {
        ...mapGetters({
            availableLanguages: 'languageSettings/getAvailableLanguages',
            currentLanguage: 'languageSettings/getCurrentLanguage',
        }),
        defaultTranslations() {
            let translations = this.availableLanguages.map(x => {
                return { title: '', locale: x};
            });
            
            return translations.sort((a, b) => {
                return (b.locale === this.currentLanguage && a.locale !== this.currentLanguage) ? 1 : -1;
            });
        }
    },
};
</script>
