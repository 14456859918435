import { debounce } from 'lodash';
export default {
    props: {
        value: {
            type: String,
            default: null
        }
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set: debounce(function(val) {
                this.$emit('input', val);
            }, 500),
        },
    },
};
