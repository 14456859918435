<template>
    <div class="company-group-help">
        <CompanyGroupNotification />
    </div>
</template>

<script>
import CompanyGroupNotification from '@/components/settings/companyGroupNotification/index.vue';

export default {
    name: 'SettingsCompanyGroupNotification',
    components: {
        CompanyGroupNotification,
    },
};
</script>
