<template>
    <v-container fluid class="ma-0 mb-3 pa-0 pl-2 pt-3">
        <v-row class="justify-space-arround">
            <v-col cols="12" xl="10">
                <v-card class="default" outlined height="100%">
                    <EditorToolbar
                        :headline="model.translations.find(x => x.locale === currentLanguage).title"
                        :isModified="isModified"
                        :isWaiting="isWaiting"
                        :editMode="editMode"
                        @onSaveItem="$emit('onSaveItem')"
                        @onDeleteItem="$emit('onDeleteItem')"
                        @onCreateItem="$emit('onCreateItem')"
                        />
                    <v-card-text>
                        <v-autocomplete
                            v-model="model.alias"
                            :label="$t('settings.companyGroupNotification.alias.fieldLabel')"
                            :hint="$t('settings.companyGroupNotification.alias.fieldHint')"
                            :items="aliasItems"
                            item-value="alias"
                            item-text="name"
                            :error-messages="errors.collect('alias')"
                            data-vv-name="alias"
                            v-validate="'required'"
                            spellcheck="true"
                            persistent-hint
                            @input="$emit('modified', $event)"
                        />
                        <v-text-field
                            v-model="model.deadline"
                            :label="$t('settings.companyGroupNotification.deadline.fieldLabel')"
                            :hint="$t('settings.companyGroupNotification.deadline.fieldHint')"
                            :error-messages="errors.collect('deadline')"
                            data-vv-name="deadline"
                            v-validate="'required|integer'"
                            required
                            persistent-hint
                            class="pt-6"
                            type="number"
                            @input="$emit('modified', $event)"
                        />
                    </v-card-text>
                    <v-card-text class="pa-0">
                        <LeaTranslationTableInput v-model="model.translations" required autofocus @modified="$emit('modified', $event)" fieldValue="title" :fieldText="'label'" fieldType="text" />
                    </v-card-text>
                    <v-card-text class="pa-0">
                        <LeaTranslationTableInput v-model="model.translations" required @modified="$emit('modified', $event)" fieldValue="content" :fieldText="$t('settings.companyGroupNotification.content.fieldLabel')" fieldType="editor" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { isUUID } from '@/components/vvt/ModelMixin.js';
import EditorToolbar from '../EditorToolbar.vue';
import LeaTranslationTableInput from '@/components/Input/LeaTranslationTableInput';

export default {
    name: 'SettingsGroupsOfPersonsEditorMask',
    inject: {
        $validator: '$validator',
    },
    components: {
        EditorToolbar,
        LeaTranslationTableInput
    },
    props: {
        aliasItems: {
            type: Array,
            default: () => []
        },
        value: {
            type: Object,
            default: null
        },
        availableLanguages: {
            type: Array,
            default: () => []
        },
        currentLanguage: {
            type: String,
            default: null
        },
        isWaiting: {
            type: Boolean,
            default: false
        },
        isModified: {
            type: Boolean,
            default: false
        },
    },
    i18n: {
        messages: {
            en: require('@/locales/Settings/en.json'),
            de: require('@/locales/Settings/de.json'),
        },
    },
    data() {
        return {
            model: this.value
        };
    },
    mounted() {
        if (isUUID(this.model.id)) {
            this.model.clientId = this.value.id;
        }
    },
    computed: {
        editMode() {
            return !isUUID(this.model.id);
        },
    },
};
</script>
