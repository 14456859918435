<template>
  <v-card
    @click.stop.passive="emitHelpMode"
    @focus.stop.capture="emitHelpMode"
    align-start
    height="100%"
    :elevation="flat ? 0 : 1"
    :color="flat ? 'transparent' : ''"
  >
      <v-card-text :class="{'pa-0 ma-0': flat, 'px-0 pb-0': !flat}">
        <v-textarea
            :label="label"
            :hint="hint"
            v-model="model"
            persistent-hint
            :filled="filled"
            background-color="transparent"
            :clearable="clearable"
            :error-messages="
                errors.collect(
                    formScope + '.' + fieldName + (rowId ? rowId : '')
                )
            "
            :data-vv-name="fieldName + (rowId ? rowId : '')"
            :data-vv-scope="formScope"
            v-validate="required ? 'required' : ''"
            :required="required"
            :disabled="loading || disabled || disabledComp"
            :loading="loading"
            spellcheck="true"
            :autofocus="autofocus"
            :prepend-inner-icon="prependInnerIcon"
            @input="$emit('input', $event)"
            @change="$emit('modified', $event)"
            auto-grow
            :rows="rows"
            :ref="this.formScope + '_' + this.groupName + '_' + this.fieldName + '_' + this.rowId"
          >
            <template v-slot:append-outer>
                <v-tooltip v-if="!helpMode && companyGroupHelp && companyGroupHelp.fieldIcon" bottom color="primary">
                <template v-slot:activator="{ on }">
                    <v-btn
                    v-on="on"
                    icon
                    large
                    color="primary"
                    class="mr-3"
                    @click="setHelpMode(true)"
                    >
                    <v-icon v-text="companyGroupHelp.fieldIcon" />
                    </v-btn>
                </template>
                    {{ $t('furtherInformation') }}
                </v-tooltip>
            </template>
        </v-textarea>
      </v-card-text>
  </v-card>
</template>

<script>
import Debounceable from '@/components/Debounceable';
import { mapGetters, mapActions } from 'vuex';
const MAIN_ENTITY = 'App\\Entity\\ProcessingActivity\\ProcessingActivity';

export default {
    mixins: [Debounceable],
    inject: {
        $validator: '$validator',
    },
    data () {
        return {
            disabledComp: false
        }
    },
    computed: {
        ...mapGetters({
            helpMode: 'processingActivityModel/getHelpModeState',
            getCompanyHelp: 'companyGroupHelp/getByEntityAndFieldName',
        }),
        companyGroupHelp() {
            return this.getCompanyHelp(MAIN_ENTITY, this.fieldName, this.childFieldName);
        },
    },
    methods: {
        ...mapActions({
            setHelpMode: 'processingActivityModel/setHelpModeState',
        }),
        disable () {
            this.disabledComp = true;
        },
        enable () {
            this.disabledComp = false;
        },
        calculateInputHeight() {
            this.$nextTick().then(() => {
                const ref1 = this.$refs[this.formScope + '_' + this.groupName + '_' + this.fieldName + '_' + this.rowId];
                ref1.calculateInputHeight();
            });
        },
        emitHelpMode () {
            this.$emit('help-mode', {
                title: this.label,
                text: this.helpModeText,
                fieldName: this.fieldName,
                childFieldName: this.childFieldName
            })
        }
    },
    props: {
        formScope: {
            type: String,
            default: 'default',
        },
        groupName: {
            type: String,
            default: 'default',
        },
        fieldName: {
            type: String,
            default: 'default',
        },
        childFieldName: {
            type: String,
            default: 'default',
        },
        helpModeText: {
            type: String,
            default: null,
        },
        step: {
            type: Number,
            default: 1,
        },
        create: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        hint: {
            type: String,
            default: null,
        },
        filled: {
            type: Boolean,
            default: true,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        rowId: {
            type: [Number, String, null],
            default: 1
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        prependInnerIcon: {
            type: String,
            default: null,
        },
        flat: {
            type: Boolean,
            default: false,
        },
        rows: {
            type: [Number, String],
            default: 5,
        },
    },
    watch: {
        disabled (val) {
            this.disabledComp = val;
        }
    }
};
</script>
