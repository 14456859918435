<template>
    <div>
        <v-toolbar flat extended extension-height="32" color="secondary">
            <template v-slot:extension>
                <v-toolbar-title
                    v-if="headline"
                    class="overline"
                    :class="$dynamicTextColorPrimary($vuetify.theme.dark)"
                    v-text="headline"
                />
            </template>
            <v-menu
                bottom
                right
                v-model="menuState"
                tile
                nudge-top="6"
                nudge-left="4"
            >
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    class="mt-n1"
                >
                    <v-icon color="primary">mdi-dots-vertical</v-icon>
                </v-btn>
                </template>
                <v-list class="py-0">
                    <v-list-item @click="menuState = !menuState">
                        <v-list-item-icon>
                            <v-icon color="primary">mdi-arrow-left</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content />
                    </v-list-item>
                    <v-list-item v-if="showNew" @click="$emit('onCreateItem')" :disabled="isWaiting || isModified" :loading="isWaiting">
                        <v-list-item-icon>
                            <v-icon :color="isWaiting || isModified ? 'grey lighten-1' : ''" v-text="'mdi-plus-circle-outline'" />
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-t="'new'" />
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="showSave" @click="$emit('onSaveItem')" :disabled="!isModified || isWaiting" :loading="$wait.is('saving selected item')">
                        <v-list-item-icon>
                            <v-badge
                                :value="isModified"
                                color="success"
                                dot
                                >
                                    <v-icon :color="isModified ? '' : 'grey lighten-1'" v-text="'mdi-content-save-edit'" />
                            </v-badge>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-t="'save'" />
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="showDelete" @click="$emit('onDeleteItem')" :disabled="!editMode || isWaiting" :loading="$wait.is('deleting selected item')">
                        <v-list-item-icon>
                            <v-icon :color="!editMode || isWaiting ? 'grey lighten-1' : ''" v-text="'mdi-close'" />
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-t="'delete'" />
                        </v-list-item-content>
                    </v-list-item>
                    <template v-if="showUsageAndReplacement">
                        <v-tooltip
                        bottom
                        color="primary"
                        >
                            <template v-slot:activator="{ on }">
                                <v-list-item :disabled="!editMode || isWaiting" @click.stop="$emit('onFindUsage')" v-on="on">
                                    <v-list-item-icon>
                                        <v-icon :class="{'animate-spin': $wait.is('find usage')}" v-text="$wait.is('find usage') ? 'mdi-loading' : 'mdi-find-replace'" />
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title v-t="'usageAndReplacement.title'" />
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <span v-t="'usageAndReplacement.hint'" />
                        </v-tooltip>
                    </template>
                    <v-list-item-group v-if="showTranslationState" v-model="translationStateModel" color="primary">
                        <v-list-item :value="1" :disabled="isWaiting">
                            <v-list-item-icon>
                                <v-icon v-text="'mdi-web'" />
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-t="translationStateModel ? 'hideTranslations' : 'showTranslations'" />
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
            <v-badge
                :value="isModified && !isWaiting"
                color="success"
                dot
                :offset-y="16"
                :offset-x="11"
                >
                <v-btn
                    icon
                    :loading="$wait.is('saving selected item')"
                    :disabled="!isModified || isWaiting"
                    @click="$emit('onSaveItem')"
                    class="mx-1"
                    >
                    <v-icon v-text="'mdi-content-save-edit'" />
                </v-btn>
            </v-badge>
        </v-toolbar>
        <v-divider />
    </div>
</template>

<script>
export default {
    name: 'SettingsEditorToolbar',
    props: {
        buttons: {
            type: Array,
            default: () => ['new', 'save', 'delete', 'translationState']
        },
        headline: {
            type: String,
            default: null
        },
        isModified: {
            type: Boolean,
            default: false
        },
        isWaiting: {
            type: Boolean,
            default: false
        },
        editMode: {
            type: Boolean,
            default: false
        },
        disableDeletion: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            menuState: false
        }
    },
    computed: {
        showNew () {
            return this.buttons.includes('new');
        },
        showSave () {
            return this.buttons.includes('save');
        },
        showDelete () {
            if(this.disableDeletion) {
                return false;
            }
            
            return this.buttons.includes('delete');
        },
        showTranslationState () {
            return this.buttons.includes('translationState');
        },
        showUsageAndReplacement () {
            return this.buttons.includes('usageAndReplacement');
        },
        translationStateModel: {
            get () {
                return this.$translationState.getValue() | 0;
            },
            set() {
                this.$translationState.setValue(!this.$translationState.getValue());
            }
        }
    }
}
</script>