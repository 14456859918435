import { eventBus } from '@/event-bus.js';
export default {
    methods: {
        initScrollHeader() {
            eventBus.$on('scrollHeader_update', this.updateHeaders);

            const scrollEvent = new Event('scroll', { bubbles: true, cancelable: false });
            window.addEventListener('scroll', this.updateHeaders);
            window.dispatchEvent(scrollEvent);

            const resizeEvent = new Event('resize', { bubbles: true, cancelable: false });
            window.addEventListener('resize', this.updateHeaders);
            window.dispatchEvent(resizeEvent);

            this.updateHeaders();
        },
        /*
        initScrollHeader() {
            eventBus.$on('scrollHeader_update', this.updateHeaders);

            const scrollEvent = document.createEvent('HTMLEvents');
            scrollEvent.initEvent('scroll', true, false);

            window.addEventListener('scroll', this.updateHeaders);
            window.dispatchEvent(scrollEvent);

            const resizeEvent = document.createEvent('HTMLEvents');
            resizeEvent.initEvent('resize', true, false);

            window.addEventListener('resize', this.updateHeaders);
            window.dispatchEvent(resizeEvent);

            this.updateHeaders();
        },
        */
        async updateHeaders() {
            await this.$nextTick();
            const scrollHeaderHeightAddition = document.querySelector('.scroll-header-height-addition');
            const columnRight = document.querySelector('.fixable-column');
            if (columnRight && this.$vuetify.breakpoint.lgAndUp) {
                columnRight.style.width = this.$outerWidth(columnRight.parentNode) + 'px';
                let columnRightTop = this.$vuetify.application.top;
                if (scrollHeaderHeightAddition) {
                    columnRightTop += this.$outerHeight(scrollHeaderHeightAddition);
                }
                columnRight.style.top = columnRightTop + 24 + 'px';
                const fixableRightColumnScroller = document.querySelector(
                    '.fixable-column-scroller'
                );
                if (fixableRightColumnScroller) {
                    let columnRightHeight =
                        this.$windowHeight() - columnRightTop - 100;
                    columnRightHeight =
                        columnRightHeight < 200 ? 200 : columnRightHeight;
                    fixableRightColumnScroller.style.maxHeight =
                        columnRightHeight + 'px';
                }
            } else if(columnRight) {
                columnRight.style.width = 'inherit';
                columnRight.style.top = 'inherit';
            }
        },
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.updateHeaders);
        window.removeEventListener('resize', this.updateHeaders);
        eventBus.$off('scrollHeader_update', this.updateHeaders);
    },
};
