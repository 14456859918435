<template>
    <v-simple-table :class="{'secondary': $translationState.getValue() }">
        <template v-slot:default>
        <thead>
            <tr>
                <th class="text-left caption" v-t="fieldText" />
                <th v-show="$translationState.getValue()" class="text-left caption" style="width:240px;" v-t="'language'" />
            </tr>
        </thead>
        <tbody>
            <tr
            v-for="translation in value"
            :key="translation.locale"
            v-show="currentLanguage === translation.locale || $translationState.getValue()"
             :class="{'v-data-table__empty-wrapper': !$translationState.getValue() }"
            >
            <td v-if="fieldType === 'text'">
                <LeaTextField 
                    v-model="translation[fieldValue]"
                    :clearable="currentLanguage !== translation.locale"
                    :required="required && currentLanguage === translation.locale"
                    :hint="currentLanguage !== translation.locale ? $t('clearForTranslation') : ''"
                    :prepend-inner-icon="currentLanguage !== translation.locale ? 'mdi-web' : ''"
                    :autofocus="autofocus && currentLanguage === translation.locale"
                    @input="$emit('modified', $event)"
                    flat
                    :filled="false"
                    :rowId="null"
                    :fieldName="fieldValue + '__' + translation.locale"
                    :disabled="disabled"
                    :childFieldName="childFieldName"
                />
            </td>
            <td v-else-if="fieldType === 'textarea'">
                <LeaTextArea
                    v-model="translation[fieldValue]"
                    :clearable="currentLanguage !== translation.locale"
                    :required="required && currentLanguage === translation.locale"
                    :hint="currentLanguage !== translation.locale ? $t('clearForTranslation') : ''"
                    :prepend-inner-icon="currentLanguage !== translation.locale ? 'mdi-web' : ''"
                    :autofocus="autofocus && currentLanguage === translation.locale"
                    @input="$emit('modified', $event)"
                    flat
                    :filled="false"
                    :disabled="disabled"
                    :rows="3"
                    :rowId="null"
                    :fieldName="fieldValue + '__' + translation.locale"
                    :childFieldName="childFieldName"
                />
            </td>
            <td v-else-if="fieldType === 'editor'" class="text-left">
                <LeaTipTapEditor
                    v-model="translation[fieldValue]"
                    :hint="currentLanguage !== translation.locale ? $t('clearForTranslation') : ''"
                    :error-messages="errors.collect(fieldValue + '__' + translation.locale)"
                    persistent-hint
                    :data-vv-name="fieldValue + '__' + translation.locale"
                    spellcheck="true"
                    :clearable="currentLanguage !== translation.locale"
                    v-validate="currentLanguage === translation.locale ? 'required' : ''"
                    :required="required && currentLanguage === translation.locale"
                    disableExtendedFormat
                    :prepend-inner-icon="currentLanguage !== translation.locale ? 'mdi-web' : ''"
                    :autofocus="autofocus && currentLanguage === translation.locale"
                    :disabled="disabled"
                    class="pb-3 pt-3"
                    :rowId="null"
                    :fieldName="fieldValue + '__' + translation.locale"
                    @input="$emit('modified', $event)"
                />
            </td>
            <td v-show="$translationState.getValue()">
                {{ $t('languages.' + availableLanguages.find(x => x === translation.locale)) }}<br />
                <v-chip v-if="currentLanguage === translation.locale" color="secondary darken-3" small label v-t="'sourceLanguage'" />
            </td>
            </tr>
        </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import { mapGetters } from 'vuex';
import LeaTextField from '@/components/Input/LeaTextField.vue';
import LeaTextArea from '@/components/Input/LeaTextArea.vue';
import LeaTipTapEditor from '@/components/Input/LeaTipTapEditor.vue';

export default {
    name: 'SettingsLeaTranslationTableInput',
    inject: {
        $validator: '$validator',
    },
    components: {
        LeaTextField,
        LeaTextArea,
        LeaTipTapEditor
    },
    props: {
        value: {
            type: Array,
            default: () => []
        },
        fieldValue: {
            type: String,
            default: 'title'
        },
        fieldText: {
            type: String,
            default: 'title'
        },
        fieldType: {
            type: String,
            default: 'text'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        childFieldName: {
            type: String,
            default: null,
        }
    },
    computed: {
        ...mapGetters({
            availableLanguages: 'languageSettings/getAvailableLanguages',
            currentLanguage: 'languageSettings/getCurrentLanguage'
        })
    }
}
</script>